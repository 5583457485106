<script setup>
import { scrollToTitle } from "@/composables/common";

const visible = ref(false);
const collapseAboutUs = ref(false);
const collapseFieldOfActivity = ref(false);
const collapseSolution = ref(false);
const collapseNewAndEvent = ref(false);

const route = useRoute();
console.log(route.path);
const reload = () => {
  window.location.reload();
};
</script>

<template>
  <header>
    <div class="2xl:container mx-auto flex-1 flex gap-x-[40px] justify-between items-center">
      <nuxt-link to="/">
        <nuxt-picture
          loading="lazy"
          src="/images/logo-1.svg"
          :img-attrs="{ alt: 'logo' }"
          width="200"
          class="max-xl:hidden"
        />
        <nuxt-picture
          loading="lazy"
          src="/images/logo-1.svg"
          :img-attrs="{ alt: 'logo' }"
          width="200"
          class="hidden max-xl:block"
        />
      </nuxt-link>
      <div class="nav-menu flex gap-x-[50px] max-xl:hidden">
        <div class="relative">
          <nuxt-link
            to="/ve-chung-toi"
            class="hover:text-[#003D8A] font-normal"
            :class="$route.path.includes('/ve-chung-toi') ? 'text-[#003D8A] font-semibold' : 'text-black'"
          >
            Về chúng tôi
          </nuxt-link>
          <div class="sub-menu hidden absolute top-[100%] left-0 pt-0">
            <nuxt-link
              to="/ve-chung-toi"
              class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
              @click="scrollToTitle('our-story')"
              >Câu chuyện thương hiệu</nuxt-link
            >
            <nuxt-link
              to="/ve-chung-toi"
              class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
              @click="scrollToTitle('history')"
            >
              Lịch sử hình thành
            </nuxt-link>
            <nuxt-link
              to="/ve-chung-toi"
              class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
              @click="route.path === '/ve-chung-toi' && reload()"
              >Tầm nhìn - Sứ mệnh - Giá trị cốt lõi</nuxt-link
            >
            <nuxt-link
              to="/ve-chung-toi"
              class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
              @click="scrollToTitle('ecosystem')"
              >Công Ty Thành Viên</nuxt-link
            >
          </div>
        </div>
        <div class="relative">
          <nuxt-link
            to="/linh-vuc-hoat-dong"
            class="hover:text-[#003D8A] font-normal"
            :class="$route.path.includes('/linh-vuc-hoat-dong') ? 'text-[#003D8A] font-semibold' : 'text-black'"
          >
            Lĩnh vực hoạt động
          </nuxt-link>
          <div class="sub-menu-c1 hidden absolute top-[100%] left-0 pt-0">
            <div class="sub-menu-c2">
              <div class="!text-[14px] text-[#005992] !font-semibold sub-menu-item hover:!text-[#003D8A]">
                Chuyển đổi số
              </div>
              <nuxt-link to="https://miraway.vn/" class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                >Trải nghiệm giao dịch toàn trình
              </nuxt-link>
              <nuxt-link
                to="http://datanomics.com.vn/"
                class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                >Hạ tầng & phân tích dữ liệu
              </nuxt-link>
              <nuxt-link to="https://qrx.com.vn/" class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                >Quản lý kênh phân phối D2C
              </nuxt-link>
              <nuxt-link to="https://goby.vn/" class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                >Tự động hóa Marketing
              </nuxt-link>
              <nuxt-link
                to="https://chuyendoiso.miraway.vn/"
                class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                >Chuyển đổi số toàn diện
              </nuxt-link>
              <div class="!text-[14px] text-[#568739] !font-semibold sub-menu-item hover:!text-[#3F7C1A]">
                Chuyển đổi xanh
              </div>
              <nuxt-link
                to="https://mirawaygroup.com/blog/giai-phap-chuyen-doi-xanh/"
                class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                >Dịch vụ tư vấn Chuyển đổi xanh
              </nuxt-link>
              <nuxt-link
                to="https://mirawaygroup.com/blog/giai-phap-chuyen-doi-xanh/"
                class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                >Giải pháp Chuyển đổi xanh
              </nuxt-link>
            </div>
          </div>
        </div>
        <div class="relative">
          <nuxt-link
            to="/giai-phap"
            class="hover:text-[#003D8A] font-normal"
            :class="$route.path.includes('/giai-phap') ? '!text-[#003D8A] font-semibold' : '!text-black'"
          >
            Giải pháp
          </nuxt-link>
          <div class="sub-menu-c1 hidden absolute top-[100%] left-0 pt-0">
            <div class="sub-menu-c2">
              <div class="flex">
                <div class="flex-1 flex flex-col">
                  <div class="!text-[14px] !font-semibold sub-menu-item hover:!text-[#003D8A]">Kênh offline</div>
                  <nuxt-link
                    to="https://miraway.vn/giai-phap/digital-signage"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    target="blank"
                    >Digital Signage</nuxt-link
                  >
                  <nuxt-link
                    to="https://miraway.vn/giai-phap/self-service-kiosk"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    target="blank"
                  >
                    Self - service Kiosk
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/giai-phap/he-thong-xep-hang-tu-dong"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    target="blank"
                  >
                    Đặt lịch xếp hàng
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/giai-phap/vms-camera"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    target="blank"
                  >
                    VMS- AI Camera
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/giai-phap/so-hoa-tai-lieu"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    target="blank"
                  >
                    Số hóa tài liệu
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/giai-phap/trung-tam-du-lieu"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    target="blank"
                  >
                    Hạ tầng dữ liệu
                  </nuxt-link>
                </div>
                <div class="flex-1 flex flex-col">
                  <div class="!text-[14px] !font-semibold sub-menu-item hover:!text-[#003D8A]">Kênh online</div>
                  <nuxt-link
                    to="https://miraway.vn/giai-phap/cdp"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    target="blank"
                  >
                    Phân tích dữ liệu khách hàng
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/giai-phap/cx"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    target="blank"
                  >
                    Trải nghiệm đa kênh
                  </nuxt-link>
                  <nuxt-link
                    to="https://miraway.vn/giai-phap/quan-ly-tai-san"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    target="blank"
                  >
                    Quản lý tài sản
                  </nuxt-link>
                  <nuxt-link
                    to="https://qrx.com.vn/"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    target="blank"
                  >
                    QRX- Nền tảng kinh doanh D2C
                  </nuxt-link>
                  <nuxt-link
                    to="https://mirawaygroup.com/blog/giai-phap-chuyen-doi-xanh/"
                    class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
                    >Chuyển đổi xanh</nuxt-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <nuxt-link
          to="/du-an"
          class="hover:text-[#003D8A] font-normal"
          :class="$route.path.includes('/du-an') ? '!text-[#003D8A] font-semibold' : '!text-black'"
          >Dự án
        </nuxt-link>
        <div class="relative hover:text-[#003D8A] font-normal">
          <nuxt-link to="https://mirawaygroup.com/blog/" class="hover:text-[#003D8A] font-normal"
            >Tin tức & Sự kiện</nuxt-link
          >
          <div class="sub-menu hidden absolute top-[100%] left-0 pt-0">
            <nuxt-link
              to="https://mirawaygroup.com/blog/category/tin-tuc-su-kien/tin-tuc/"
              class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
              >Tin tức</nuxt-link
            >
            <nuxt-link
              to="https://mirawaygroup.com/blog/category/tin-tuc-su-kien/su-kien/"
              class="!text-[14px] sub-menu-item hover:!text-[#003D8A] font-normal"
            >
              Sự kiện
            </nuxt-link>
            <nuxt-link
              to="https://mirawaygroup.com/blog/category/tin-tuc-su-kien/hop-tac-doi-tac/"
              class="!text-[14px] sub-menu-item hover:!text-[#003D8A]"
              >Hợp tác đối tác</nuxt-link
            >
            <nuxt-link
              to="https://mirawaygroup.com/blog/category/tin-tuc-su-kien/truyen-thong-noi-ve-miraway/"
              class="!text-[14px] sub-menu-item hover:!text-[#003D8A]"
              >Truyền thông nói về Miraway</nuxt-link
            >
          </div>
        </div>
        <nuxt-link
          to="/tuyen-dung"
          class="hover:text-[#003D8A] font-normal"
          :class="$route.path.includes('/tuyen-dung') ? '!text-[#003D8A] font-semibold' : '!text-black'"
          >Tuyển dụng
        </nuxt-link>
        <nuxt-link
          to="/lien-he"
          class="hover:text-[#003D8A] font-normal"
          :class="$route.path.includes('/lien-he') ? '!text-[#003D8A] font-semibold' : '!text-black'"
        >
          Liên hệ
        </nuxt-link>
      </div>
    </div>

    <button class="!px-2 border-none xl:hidden" @click="visible = true" aria-label="icon-3-dot">
      <svg viewBox="0 -53 384 384" width="28px" height="28px">
        <path
          d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
        />
        <path
          d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
        />
        <path
          d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
        />
      </svg>
    </button>

    <a-drawer
      v-model:visible="visible"
      :bodyStyle="{ paddingTop: '45px' }"
      :contentWrapperStyle="{ width: '100vw' }"
      body
      :closable="false"
      placement="right"
    >
      <template #title>
        <div class="flex items-center justify-between bg-[#003D8A] px-4">
          <nuxt-link to="/">
            <nuxt-picture loading="lazy" src="/images/logo-white.svg" :img-attrs="{ alt: 'logo' }" width="122" />
          </nuxt-link>

          <button class="small !px-2 border-none" @click="visible = false" aria-label="collapse-icon">
            <svg viewBox="0 0 329.26933 329" width="20px" height="20px" class="fill-white">
              <path
                d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
              />
            </svg>
          </button>
        </div>
      </template>

      <div class="py-3 px-2 hover:text-[#003D8A] font-bold">
        <nuxt-link to="/" @click="visible = false">Trang chủ</nuxt-link>
      </div>

      <div class="py-0 px-2 hover:text-[#003D8A]">
        <div class="py-3 border-solid border-b border-[#E0E0E0] flex items-center justify-between font-bold">
          <nuxt-link to="/ve-chung-toi" @click="visible = false">Về chúng tôi</nuxt-link>

          <svg
            viewBox="0 0 24 24"
            width="20px"
            height="20px"
            class="fill-[black] transition-all"
            :class="{ 'rotate-180 ': !collapseAboutUs }"
            @click="collapseAboutUs = !collapseAboutUs"
          >
            <g id="_16" data-name="16">
              <path
                d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
              />
            </g>
          </svg>
        </div>

        <div
          class="sub-menu flex flex-col gap-y-4 px-2 py-4 overflow-hidden transition-all"
          :class="{ 'h-0 !py-0': !collapseAboutUs }"
        >
          <nuxt-link
            to="/ve-chung-toi"
            class="sub-menu-item normal-case subtitle-4 font-semibold"
            @click="
              visible = false;
              scrollToTitle('our-story_mb');
            "
          >
            Câu chuyện thương hiệu
          </nuxt-link>
          <nuxt-link
            to="/ve-chung-toi"
            class="sub-menu-item normal-case subtitle-4 font-semibold"
            @click="
              visible = false;
              scrollToTitle('history');
            "
          >
            Lịch sử hình thành
          </nuxt-link>
          <nuxt-link
            to="/ve-chung-toi"
            class="sub-menu-item normal-case subtitle-4 font-semibold"
            @click="visible = false; route.path === '/ve-chung-toi' && reload()"
          >
            Tầm nhìn - Sứ mệnh - Giá trị cốt lõi
          </nuxt-link>
          <nuxt-link
            to="/ve-chung-toi"
            class="sub-menu-item normal-case subtitle-4 font-semibold"
            @click="
              visible = false;
              scrollToTitle('ecosystem');
            "
          >
            Công Ty Thành Viên
          </nuxt-link>
        </div>
      </div>

      <div class="py-3 px-2 border-solid border-b border-[#E0E0E0] flex items-center justify-between">
        <nuxt-link to="/linh-vuc-hoat-dong" class="font-bold" @click="visible = false">Lĩnh vực hoạt động</nuxt-link>

        <svg
          viewBox="0 0 24 24"
          width="20px"
          height="20px"
          class="fill-black transition-all"
          :class="{ 'rotate-180 ': !collapseFieldOfActivity }"
          @click="collapseFieldOfActivity = !collapseFieldOfActivity"
        >
          <g id="_16" data-name="16">
            <path
              d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
            />
          </g>
        </svg>
      </div>

      <div
        class="sub-menu flex flex-col gap-y-4 px-2 py-4 overflow-hidden transition-all"
        :class="{ 'h-0 !py-0': !collapseFieldOfActivity }"
      >
        <div
          class="sub-menu flex flex-col gap-y-4 px-2 overflow-hidden transition-all"
          :class="{ 'h-0 !py-0': !collapseFieldOfActivity }"
        >
          <div class="text-[15px] text-[#005992] !font-bold">Chuyển đổi số</div>
          <nuxt-link
            to="https://miraway.vn"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
            >Trải nghiệm giao dịch toàn trình</nuxt-link
          >
          <nuxt-link
            to="http://datanomics.com.vn/"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
            >Hạ tầng & phân tích dữ liệu
          </nuxt-link>
          <nuxt-link
            to="https://qrx.com.vn/"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
            >Quản lý kênh phân phối D2C</nuxt-link
          >
          <nuxt-link to="https://goby.vn/" class="subtitle-4 hover:text-[#003D8A]" @click="visible = false">
            Tự động hóa Marketing
          </nuxt-link>
          <nuxt-link
            to="https://chuyendoiso.miraway.vn/"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
          >
            Chuyển đổi số toàn diện
          </nuxt-link>
          <div class="text-[15px] text-[#568739] !font-bold">Chuyển đổi xanh</div>
          <nuxt-link
            to="https://mirawaygroup.com/blog/giai-phap-chuyen-doi-xanh/"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
          >
            Dịch vụ tư vấn Chuyển đổi xanh
          </nuxt-link>
          <nuxt-link
            to="https://mirawaygroup.com/blog/giai-phap-chuyen-doi-xanh/"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
          >
            Giải pháp Chuyển đổi xanh
          </nuxt-link>
        </div>
      </div>

      <div class="py-3 px-2 border-solid border-b border-[#E0E0E0] flex items-center justify-between">
        <nuxt-link to="/giai-phap" :external="true" class="hover:text-[#003D8A] font-semibold">Giải pháp</nuxt-link>

        <svg
          viewBox="0 0 24 24"
          width="20px"
          height="20px"
          class="fill-black transition-all"
          :class="{ 'rotate-180 ': !collapseSolution }"
          @click="collapseSolution = !collapseSolution"
        >
          <g id="_16" data-name="16">
            <path
              d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
            />
          </g>
        </svg>
      </div>

      <div
        class="sub-menu flex flex-col gap-y-4 px-2 py-4 overflow-hidden transition-all"
        :class="{ 'h-0 !py-0': !collapseSolution }"
      >
        <div
          class="sub-menu flex flex-col gap-y-4 px-2 overflow-hidden transition-all"
          :class="{ 'h-0 !py-0': !collapseSolution }"
        >
          <div class="text-[15px] !font-bold">Kênh offline</div>
          <nuxt-link
            to="https://miraway.vn/giai-phap/digital-signage"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
          >
            Digital Signage
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/giai-phap/self-service-kiosk"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
          >
            Self - service Kiosk
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/giai-phap/he-thong-xep-hang-tu-dong"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
          >
            Đặt lịch xếp hàng
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/giai-phap/vms-camera"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
          >
            VMS- AI Camera
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/giai-phap/so-hoa-tai-lieu"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
          >
            Số hóa tài liệu
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/giai-phap/trung-tam-du-lieu"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
          >
            Hạ tầng dữ liệu
          </nuxt-link>

          <div class="text-[15px] !font-bold">Kênh online</div>
          <nuxt-link
            to="https://miraway.vn/giai-phap/cdp"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
          >
            Phân tích dữ liệu khách hàng
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/giai-phap/cx"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
          >
            Trải nghiệm đa kênh
          </nuxt-link>
          <nuxt-link
            to="https://miraway.vn/giai-phap/quan-ly-tai-san"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
          >
            Quản lý tài sản
          </nuxt-link>
          <nuxt-link
            to="https://qrx.com.vn/"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
            target="blank"
          >
            QRX- Nền tảng kinh doanh D2C
          </nuxt-link>
          <nuxt-link
            to="https://mirawaygroup.com/blog/giai-phap-chuyen-doi-xanh/"
            class="subtitle-4 hover:text-[#003D8A]"
            @click="visible = false"
          >
            Chuyển đổi xanh
          </nuxt-link>
        </div>
      </div>

      <div class="py-3 px-2 hover:text-[#003D8A] font-semibold">
        <nuxt-link to="/du-an" @click="visible = false">Dự án</nuxt-link>
      </div>

      <div class="py-3 px-2 border-solid border-b border-[#E0E0E0] flex items-center justify-between font-bold">
        <nuxt-link target="_blank" to="https://mirawaygroup.com/blog/" @click="visible = false"
          >Tin tức & Sự kiện</nuxt-link
        >

        <svg
          viewBox="0 0 24 24"
          width="20px"
          height="20px"
          class="fill-[black] transition-all"
          :class="{ 'rotate-180 ': !collapseNewAndEvent }"
          @click="collapseNewAndEvent = !collapseNewAndEvent"
        >
          <g id="_16" data-name="16">
            <path
              d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"
            />
          </g>
        </svg>
      </div>

      <div
        class="sub-menu flex flex-col gap-y-4 px-2 py-4 overflow-hidden transition-all"
        :class="{ 'h-0 !py-0': !collapseNewAndEvent }"
      >
        <nuxt-link
          target="_blank"
          to="https://mirawaygroup.com/blog/category/tin-tuc-su-kien/tin-tuc/"
          class="sub-menu-item normal-case subtitle-4 font-semibold"
          @click="visible = false"
        >
          Tin tức
        </nuxt-link>
        <nuxt-link
          target="_blank"
          to="https://mirawaygroup.com/blog/category/tin-tuc-su-kien/su-kien/"
          class="sub-menu-item normal-case subtitle-4 font-semibold"
          @click="visible = false"
        >
          Sự kiện
        </nuxt-link>
        <nuxt-link
          target="_blank"
          to="https://mirawaygroup.com/blog/category/tin-tuc-su-kien/hop-tac-doi-tac/"
          class="sub-menu-item normal-case subtitle-4 font-semibold"
          @click="visible = false"
        >
          Hợp tác đối tác
        </nuxt-link>
        <nuxt-link
          target="_blank"
          to="https://mirawaygroup.com/blog/category/tin-tuc-su-kien/truyen-thong-noi-ve-miraway/"
          class="sub-menu-item normal-case subtitle-4 font-semibold"
          @click="visible = false"
        >
          Truyền thông nói về Miraway
        </nuxt-link>
      </div>

      <div class="py-3 px-2 hover:text-[#003D8A] font-semibold">
        <nuxt-link to="/tuyen-dung" @click="visible = false">Tuyển dụng</nuxt-link>
      </div>

      <div class="flex-center max-sm:flex-col gap-4 mt-4">
        <nuxt-link to="/lien-he" class="flex-1 w-full">
          <button class="primary large w-full" @click="visible = false">Liên hệ</button>
        </nuxt-link>
      </div>
    </a-drawer>
  </header>
</template>
